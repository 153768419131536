import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  PasswordInput,
  minLength,
  SelectArrayInput
} from 'react-admin';
import { Grid, Typography } from "@mui/material"

const notEmptyOrNull = (value) => {
  if (value === '' || value === null || value === undefined) {
      return 'Este campo no puede ser vacío o nulo';
  }
  return undefined;  // Devuelve `undefined` si la validación pasa
};


const UserEdit = (props) => (
  <Edit  {...props} >
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Datos de compañia
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="company.name" label="Nombre de compañia" fullWidth  validate={notEmptyOrNull}/>
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="company.approxshipmentsPerMonth" label="# Envios por mes" fullWidth validate={notEmptyOrNull} />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="company.activity" label="Actividad de la compañia" fullWidth validate={notEmptyOrNull} />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="curp" label="CURP" fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <NumberInput source="credits" label="Total de créditos" min={0} max={1000000} error={true} fullWidth validate={notEmptyOrNull} />
        </Grid>
        <Grid item xs={6} md={6}>
          <NumberInput source="discount" label="Descuento" min={0} max={100} error={true} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Factores Multiplicadores de Paqueterías
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <NumberInput source="multiplierFactors.express" label="Factor multiplicador para Express" min={0} max={100} error={true} fullWidth />
        </Grid>
        <Grid item xs={4} md={4}>
          <NumberInput source="multiplierFactors.fedex" label="Factor multiplicador para Fedex" min={0} max={100} error={true} fullWidth />
        </Grid>
        <Grid item xs={4} md={4}>
          <NumberInput source="multiplierFactors.dhl" label="Factor multiplicador para Dhl" min={0} max={100} error={true} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Datos de contacto
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="contact.email" label="Correo" fullWidth validate={notEmptyOrNull} />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="contact.phone" label="Número telefónico" fullWidth validate={notEmptyOrNull} />
        </Grid>
        <Grid item xs={3} md={3}>
          <PasswordInput source="password" label="Contraseña" validate={[minLength(8)]} fullWidth  />
        </Grid>
        <Grid item xs={3} md={3}>
          <SelectArrayInput source="shippingServices" label="Servicios a mostrar" fullWidth choices={[
            { id: 'DHL', name: 'DHL' },
            { id: 'FEDEX', name: 'FEDEX' },
            { id: 'EXPRESS', name: 'EXPRESS' },
            { id: 'INABIT', name: 'INABIT' },
          ]} />
        </Grid>
      </Grid>
      <NumberInput label="Estado" source="status" min={1} max={3} />
      <TextInput source="referenceOnGuide" label="Referencia en Guía" fullWidth />
    </SimpleForm>
  </Edit >
);

export default UserEdit;
