import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const APPLICATION = process.env.REACT_APP_APPLICATION;
const SECRET = process.env.REACT_APP_SECRET;
const headers = {
  application: APPLICATION,
  secret: SECRET
}

const provider = {
  login: async ({ username, password }) => {
    const login = await axios.post(`${API_URL}/admin/user/signIn`,
      {
        email: username,
        password
      },
      {
        headers: headers
      })
    localStorage.setItem('@token', login.data.token);
    return Promise.resolve({});
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('@token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('@token')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};


export default provider;