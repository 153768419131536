import * as React from "react";
import {
  Create,
  SimpleForm,
  FileInput,
  SelectInput,
  FileField,
  required,

} from 'react-admin';
import { Grid, Typography } from "@mui/material"

const services = [
  { id: 'FEDEX', name: 'FEDEX' },
  { id: 'EXPRESS', name: 'EXPRESS' },
  { id: 'DHL', name: 'DHL' },
];

const validFormats = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Crear nueva conciliación
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <FileInput source="document" fullWidth accept={validFormats} validate={[required()]} multiple={false} label="Reporte" title="title"  >
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
        <Grid item xs={3} md={3}>
          <SelectInput source="service" choices={services} label="Paquetería" validate={[required()]} />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default UserCreate;
