import * as React from "react";
import { List, Datagrid, TextField, EmailField, DateField } from 'react-admin';


const UserList = props => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="company.name" label="Company" />
        <EmailField source="contact.email" label="Email" />
        <TextField source="contact.phone" label="Phone" />
        <TextField source="discount" label="Discount On Packages (%)" />
        <TextField source="status" />
        <DateField source="createdAt" label="Fecha de inscripción" />
      </Datagrid>
    </List>
  )
};

export default UserList;