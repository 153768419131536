import * as React from "react";
import { List, Datagrid, TextField, EmailField, DateField } from 'react-admin';


const ConciliationList = props => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="service" label="Servicio" />
        <TextField source="nameOnS3" label="Nombre del Archivo en S3" />
        <TextField source="originalName" label="Nombre original del archivo" />
        <TextField source="status" label="Estado"/>
        <DateField source="createdAt" label="Fecha de creación" showTime={true} />
        <DateField source="updatedAt" label="Fecha de actualización" showTime={true}  />
      </Datagrid>
    </List>
  )
};

export default ConciliationList;
