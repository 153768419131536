import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  EditButton,
  useRecordContext,
} from 'react-admin';
import JsonField from '../../components/JsonField';

const CoverageEditActions = ({ basePath }) => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={record} />
    </TopToolbar >
  )
};


const CoverageShow = () => {
  return (
    <Show actions={<CoverageEditActions />}>
      <SimpleShowLayout spacing={2}>
        <TextField source="id" disabled />
        <TextField source="service" label="Servicio" />
        <TextField source="feeType" label="Tipo de Tarifa" />
        <JsonField source="coverage" maxHeight={'500px'} />
      </SimpleShowLayout>
    </Show>
  )
};

export default CoverageShow;
