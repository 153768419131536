import _ from "lodash"
export const searchString = (search) => {
  search = search.trim();
  search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  search = search.replace(new RegExp("e", "gi"), "[ée]");
  search = search.replace(new RegExp("a", "gi"), "[áa]");
  search = search.replace(new RegExp("i", "gi"), "[íi]");
  search = search.replace(new RegExp("o", "gi"), "[óo]");
  search = search.replace(new RegExp("u", "gi"), "[úu]");
  const regex = new RegExp(search, "gi");
  return regex;
};

export const cleaningNullValues = (object) => {
  for (let key in object) {
    if (object[key] === null || object[key] === undefined) {
      delete object[key]
    }
  }
  return object
}

export const pruneEmpty = (obj) => {
  return function prune(current) {
    _.forOwn(current, function (value, key) {
      if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) ||
        (_.isString(value) && _.isEmpty(value)) ||
        (_.isObject(value) && _.isEmpty(prune(value)))) {

        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete 
    // operation on an array
    if (_.isArray(current)) _.pull(current, undefined);

    return current;

  }(_.cloneDeep(obj));  // Do not modify the original object, create a clone instead
}

export const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")