import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';



const JsonField = (props) => {
  const record = useRecordContext(props);
  const value = record[props.source];

  const Pre = styled('pre')({
    maxHeight: props.maxHeight || '250px',
    overflowY: 'auto',
    padding: '20px',
    fontFamily: 'monospace',
    fontSize: '14px',
    backgroundColor: '#EEE',
    borderRadius: '5px',
  });

  return (
    <>
      <Pre>
        {JSON.stringify(value, null, 2)}
      </Pre>
    </>
  );
};

JsonField.defaultProps = {
  addLabel: true,
};

export default JsonField;
