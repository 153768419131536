import axios from 'axios';
import formData from 'form-data';
import { REACT_APP_API_URL, REACT_APP_APPLICATION, REACT_APP_SECRET } from './config/constants'

const dataProvider = {
  getList: async (resource, params) => {
    const token = localStorage.getItem('@token');
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      page: (page - 1) * perPage,
      limit: perPage,
      filter: JSON.stringify(params.filter),
    };
    if (resource === 'users') {
      const url = `${REACT_APP_API_URL}/admin/user/list?offset=${query.page}&limit=${query.limit}`;
      const result = await axios.get(url, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'guides') {
      const url = `${REACT_APP_API_URL}/admin/shipment/list?offset=${query.page}&limit=${query.limit}&filters=${query.filter}&sortField=${params.sort.field}&sortOrder=${params.sort.order}`;
      const result = await axios.get(url, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'coverages') {
      const url = `${REACT_APP_API_URL}/admin/coverage/list?offset=${query.page}&limit=${query.limit}&sortField=${params.sort.field}&sortOrder=${params.sort.order}`;
      const result = await axios.get(url, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'conciliations') {
      const url = `${REACT_APP_API_URL}/admin/conciliation/list?offset=${query.page}&limit=${query.limit}&sortField=${params.sort.field}&sortOrder=${params.sort.order}`;
      const result = await axios.get(url, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    }
  },
  getOne: async (resource, params) => {
    const token = localStorage.getItem('@token');
    const { id } = params
    if (resource === 'users') {
      const url = `${REACT_APP_API_URL}/admin/user/${id}`;
      const result = await axios.get(url, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'guides') {
      const url = `${REACT_APP_API_URL}/admin/shipment/list?offset=0&limit=20`;
      const result = await axios.get(url, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'coverages') {
      const url = `${REACT_APP_API_URL}/admin/coverage/${id}`;
      const result = await axios.get(url, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    }
  },

  // getMany: (resource, params) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params.ids }),
  //   };
  //   const url = `${apiUrl}/${resource}?${stringify(query)}`;
  //   return httpClient(url).then(({ json }) => ({ data: json }));
  // },

  // getManyReference: (resource, params) => {
  //   const { page, perPage } = params.pagination;
  //   const { field, order } = params.sort;
  //   const query = {
  //     sort: JSON.stringify([field, order]),
  //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
  //     filter: JSON.stringify({
  //       ...params.filter,
  //       [params.target]: params.id,
  //     }),
  //   };
  //   const url = `${apiUrl}/${resource}?${stringify(query)}`;

  //   return httpClient(url).then(({ headers, json }) => ({
  //     data: json,
  //     total: parseInt(headers.get('content-range').split('/').pop(), 10),
  //   }));
  // },

  update: async (resource, params) => {
    const token = localStorage.getItem('@token');
    if (resource === 'users') {
      const data = params.data
      delete data.documentUrl
      delete data.createdAt
      delete data.updatedAt
      delete data.id
      const url = `${REACT_APP_API_URL}/admin/user/${params.id}`;
      const result = await axios.put(url, { ...data }, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'coverages') {
      const data = params.data
      delete data.createdAt
      delete data.updatedAt
      delete data.id
      const url = `${REACT_APP_API_URL}/admin/coverage/${params.id}`;
      const result = await axios.put(url, { ...data }, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    }
  },

  // updateMany: (resource, params) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params.ids }),
  //   };
  //   return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
  //     method: 'PUT',
  //     body: JSON.stringify(params.data),
  //   }).then(({ json }) => ({ data: json }));
  // },

  create: async (resource, params) => {
    const token = localStorage.getItem('@token');
    if (resource === 'users') {
      const url = `${REACT_APP_API_URL}/admin/user/create`;
      const result = await axios.post(url, { ...params.data }, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'coverages') {
      const url = `${REACT_APP_API_URL}/admin/coverage/create`;
      const result = await axios.post(url, { ...params.data }, {
        headers: {
          application: REACT_APP_APPLICATION,
          secret: REACT_APP_SECRET,
          authorization: `Bearer ${token}`
        }
      })
      return Promise.resolve(result.data)
    } else if (resource === 'conciliations') {
      const formData = new FormData();
      formData.append('document', params.data.document.rawFile);
      formData.append('service', params.data.service);
      const url = `${REACT_APP_API_URL}/admin/conciliation/create`;
      try {
        const result = await axios.post(url, formData, {
          headers: {
            application: REACT_APP_APPLICATION,
            secret: REACT_APP_SECRET,
            authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          }
        })
        return Promise.resolve(result.data)
      } catch (error) {
        if(error.response.data)return Promise.reject(error.response.data.error.message)
        return Promise.reject(error)
      }
    }
  }


  // delete: (resource, params) =>
  //   httpClient(`${apiUrl}/${resource}/${params.id}`, {
  //     method: 'DELETE',
  //   }).then(({ json }) => ({ data: json })),

  // deleteMany: (resource, params) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params.ids }),
  //   };
  //   return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
  //     method: 'DELETE',
  //   }).then(({ json }) => ({ data: json }));
  // }
};

export default dataProvider;
