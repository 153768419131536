import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  PasswordInput,
  required,
  minLength,
  minValue,
  SelectArrayInput
} from 'react-admin';
import { Grid, Typography } from "@mui/material"

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Datos de contacto
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <TextInput validate={[required()]} source="contact.email" label="Correo" fullWidth />
        </Grid>
        <Grid item xs={4} md={4}>
          <TextInput validate={[required()]} source="contact.phone" label="Número telefónico" fullWidth />
        </Grid>
        <Grid item xs={4} md={4}>
          <PasswordInput source="password" validate={[required(), minLength(8)]} error={true} label="Contraseña" fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Datos de compañia
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="company.name" validate={[required()]} label="Nombre de compañia" fullWidth />
        </Grid>
        <Grid item xs={3} md={3}>
          <NumberInput source="company.approxshipmentsPerMonth" validate={[required()]} label="# Envios por mes" fullWidth />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput source="company.activity" validate={[required()]} label="Actividad de la compañia" fullWidth />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextInput validate={[required()]} source="curp" label="CURP" fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <NumberInput source="credits" validate={[required()]} label="Total de créditos" fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <NumberInput source="discount" validate={[required(), minValue(10)]} error={true} label="Descuento general" fullWidth defaultValue={0} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Factores Multiplicadores de Paqueterías
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <NumberInput source="multiplierFactors.express" label="Factor multiplicador para Express" max={100} fullWidth defaultValue={0} />
        </Grid>
        <Grid item xs={4} md={4}>
          <NumberInput source="multiplierFactors.fedex" label="Factor multiplicador para Fedex" max={100} fullWidth defaultValue={0} />
        </Grid>
        <Grid item xs={4} md={4}>
          <NumberInput source="multiplierFactors.dhl" label="Factor multiplicador para Dhl" max={100} fullWidth defaultValue={0} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Estado Usuario
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <NumberInput validate={[required()]} label="Estado" source="status" min={1} max={3} fullWidth />
        </Grid>
        <Grid item xs={9} md={9}>
          <SelectArrayInput source="shippingServices" label="Servicios de mensajería a mostrar" fullWidth choices={[
            { id: 'DHL', name: 'DHL' },
            { id: 'FEDEX', name: 'FEDEX' },
            { id: 'EXPRESS', name: 'EXPRESS' },
          ]} />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default UserCreate;
