import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FileField,
  NumberField,
  DateField,
  TopToolbar,
  ExportButton,
  DateInput,
  TextInput,
  SelectInput,
} from 'react-admin';



const ListActions = () => (
  <TopToolbar>
    <ExportButton maxResults={10000} />
    {/* <FilterButton filters={postFilters} /> */}

  </TopToolbar>
);


const postFilters = [
  <DateInput label="Fecha (Desde)" source="createdAtFrom" alwaysOn />,
  <DateInput label="Fecha (Hasta)" source="createdAtTo" alwaysOn />,
  <TextInput label="Guia" source="trackingNumber" alwaysOn />,
  <SelectInput label="Servicio" source="serviceName" choices={[
    { id: 'EXPRESS', name: 'EXPRESS' },
    { id: 'FEDEX', name: 'FEDEX' },
    { id: 'DHL', name: 'DHL' },
  ]} alwaysOn />
];

export const ShippingList = props => {
  return (
    <List {...props} actions={<ListActions />} filters={postFilters} perPage={15} >
      <Datagrid rowClick="edit" >
        <TextField source="id" />
        <TextField source="serviceName" label="Transportadora" />
        <TextField source="user.name" label="Usuario" />
        <TextField source="serviceType" label="Tipo de servicio" />
        <TextField source="trackingNumber" label="Número de rastreo" />
        <TextField source="trackStatus" label="Estado" />
        <FileField source="guideUrl" title='Documento' target="_blank" />
        <NumberField source="price" label='Costo' />
        {/* <TextField source="status" /> */}
        <DateField source="createdAt" label='Fecha de creación' showTime={true} />
      </Datagrid>
    </List>
  )
}
