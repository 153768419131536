import * as React from "react";
import { Button, Modal, Box, Grid } from '@mui/material';
import axios from 'axios';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  TopToolbar,
  NumberField,
  FileField,
  EditButton,
  useRecordContext,
} from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;
const application = process.env.REACT_APP_APPLICATION
const secret = process.env.REACT_APP_SECRET

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const handleApprove = async (id) => {
  const token = localStorage.getItem('@token');
  try {
    const url = `${apiUrl}/admin/user/validate/${id}`;
    await axios.put(url,
      { decision: 'APPROVED', notes: ['aprovado'] }
      , {
        headers: {
          application,
          secret,
          authorization: `Bearer ${token}`
        }
      })
  } catch (error) {
    console.log('ocurrio error aprobando usuario')
  }
}

const handleReject = async (id) => {
  const token = localStorage.getItem('@token');
  try {
    const url = `${apiUrl}/admin/user/validate/${id}`;
    await axios.put(url,
      { decision: 'REJECTED', notes: ['rechazado'] }
      , {
        headers: {
          application,
          secret,
          authorization: `Bearer ${token}`
        }
      })
  } catch (error) {
    console.log('ocurrio error rechazando usuario')
  }
}

const UserEditActions = ({ basePath }) => {
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={record} />
      {
        record?.status === 2 ?
          <>
            <Button variant="outlined" onClick={handleOpen}>Validar usuario</Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>¿Como desea continuar con este usuario ?</h3>
                <br />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <Button variant="outlined" color="success" onClick={() => {
                      handleApprove(record.id);
                      handleClose();
                    }
                    }>Aprobar</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="outlined" onClick={() => {
                      handleReject(record.id);
                      handleClose();
                    }}>Rechazar</Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </>
          :
          <></>
      }
    </TopToolbar >
  )
};


const UserShow = () => {
  return (
    <Show actions={<UserEditActions />}>
      <SimpleShowLayout spacing={2}>
        <TextField source="id" disabled />
        <TextField source="contact.email" label="Correo" />
        <TextField source="contact.phone" label="Número telefónico" />
        <TextField source="curp" label="CURP" />
        <TextField source="multiplierFactors" label="Factores multiplicadores" />
        <TextField source="discount" label="Descuento general" />
        <NumberField source="credits" label="Total de créditos" />
        <TextField source="company.name" label="Nombre de compañia" />
        <NumberField source="company.approxshipmentsPerMonth" label="# Envios por mes" />
        <TextField source="company.activity" label="Actividad de la compañia" />
        <DateField label="Fecha de inscripción" source="createdAt" />
        <NumberField label="Estado" source="status" min={1} max={3} />
        <FileField source="documentUrl" label="Documento" title="Documento" />
        <TextField source="shippingServices" label="Servicios de mensajería a mostrar" />
        <TextField source="referenceOnGuide" label="Referencia en guía" />
      </SimpleShowLayout>
    </Show>
  )
};

export default UserShow;
