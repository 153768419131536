import * as React from "react";
import { Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import { ShippingList } from './views/Shipping/list';

import UserList from './views/User/list';
import UserShow from './views/User/show';
import UserCreate from './views/User/create';
import UserEdit from './views/User/edit';

import CoverageList from './views/Coverage/list';
import CoverageShow from './views/Coverage/show';
// import CoverageCreate from './Coverage/create';
import CoverageEdit from './views/Coverage/edit';

import conciliationList from './views/Conciliation/list';
import conciliationCreate from './views/Conciliation/create';

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource name="users" options={{ label: 'Usuarios' }} create={UserCreate} list={UserList} show={UserShow} edit={UserEdit} />
    <Resource name="coverages" options={{ label: 'Coberturas' }} list={CoverageList} show={CoverageShow} edit={CoverageEdit} />
    <Resource name="guides" options={{ label: 'Guias generadas' }} list={ShippingList} />
    <Resource name="conciliations" options={{ label: 'Conciliaciones' }} list={conciliationList} create={conciliationCreate} />
  </Admin>
);


export default App;
