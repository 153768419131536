import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
} from 'react-admin';
import { Grid, Typography } from "@mui/material"
import { pruneEmpty } from "../../utils/parser"

const transformData = (data) => pruneEmpty(data)

const UserEdit = (props) => (
  <Edit  {...props} transform={transformData}>
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Datos de cobertura
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <TextInput source="service" label="service" fullWidth />
        </Grid>
        <Grid item xs={4} md={4}>
        <TextInput source="feeType" label="Tipo de Tarifa" fullWidth  />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography fontSize={25} fontWeight="bold">
            Cobertura
          </Typography>
        </Grid>
        <Grid item xs={2} md={2}>
          <NumberInput source="coverage.min" label="Rango Mínimo (Km)" fullWidth />
        </Grid>
        <Grid item xs={2} md={2}>
          <NumberInput source="coverage.max" label="Rango Máximo (Km)" fullWidth />
        </Grid>
        <Grid item xs={2} md={2}>
          <NumberInput source="coverage.value" label="Factor multiplicador" fullWidth />
        </Grid>
        <Grid item xs={2} md={2}>
          <TextInput source="coverage.name" label="Nombre" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit >
);

export default UserEdit;
