import * as React from "react";
import { List, Datagrid, TextField, NumberField, Js } from 'react-admin';


const UserList = props => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="service" label="Service" textAlign="center" />
        <TextField source="feeType" label="Tipo de Tarifa" textAlign="center" />
        <TextField source="coverage.name" label="Cobertura" textAlign="center" />
        <NumberField source="coverage.min" label="Cobertura Min (Km)" textAlign="center" />
        <NumberField source="coverage.max" label="Cobertura Máx (Km)" textAlign="center" />
        <NumberField source="coverage.value" label="Factor Multiplicador" textAlign="center" />
      </Datagrid>
    </List>
  )
};

export default UserList;
